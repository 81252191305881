<template>
  <img
    class=""
    :sizes="sizes"
    :src="defaultImage"
    :srcset="srcset"
    :alt="alt" >
</template>

<script>
const WIDTHS = [256, 512, 1024, 2048]

export default {
  props: {
    path: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    sizes: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      default: Infinity
    }
  },
  data () {
    return {
      defaultSize: 512,
      src: this.smallest
    }
  },
  computed: {
    defaultImage () {
      return this.createUrl(this.defaultSize)
    },
    widths () {
      if (this.max) {
        const widths = WIDTHS.filter(width => width < this.max)

        return [...widths, this.max]
      } else {
        return WIDTHS.slice()
      }
    },
    srcset () {
      return this.widths.map(width => {
        return [
          this.createUrl(width),
          `${width}w`
        ].join(' ')
      }).join(', ')
    }
  },
  methods: {
    createUrl (size) {
      return `${process.env.VUE_APP_API_CF}${this.path}/${size}`
    }
  }
}
</script>
