<template>
  <div class="flex flex-wrap mx-4 pt-20">
    <div class="w-full md:w-1/2 p-4">
      <div class="w-full h-full p-4 rounded border-2 border-primary flex flex-wrap">
        <h2 class="w-full font-medium text-lg text-center">Info</h2>
        <div class="w-full flex justify-center">
          <router-link
            :to="`/recept/${recipe.slug}`"
            class="bg-primary text-black rounded-lg p-1 text-center mb-4"
          >Preview</router-link>
        </div>

        <div class="flex items-center justify-between w-full lg:w-1/2 px-2 mt-2">
          <label for="">Naam:</label>
          <input type="text" v-model="recipe.name" class="rounded border border-primary ml-2 px-2">
        </div>
        <div class="flex items-center justify-between w-full lg:w-1/2 px-2 mt-2">
          <label for="">Slug:</label>
          <input type="text" v-model="recipe.slug" class="rounded border border-primary ml-2 px-2">
        </div>
        <div class="flex items-center justify-between w-full lg:w-1/2 px-2 mt-2">
          <label for="">Cook Time:</label>
          <input type="number" min="0" v-model="recipe.cookTime" class="rounded border border-primary ml-2 px-2">
        </div>
        <div class="flex items-center justify-between w-full lg:w-1/2 px-2 mt-2">
          <label for="">Prep Time:</label>
          <input type="number" min="0" v-model="recipe.prepTime" class="rounded border border-primary ml-2 px-2">
        </div>
        <div class="flex items-center justify-between w-full lg:w-1/2 px-2 mt-2">
          <label for="">Persons</label>
          <div class="flex">
            <div @click="addPerson(-1)" class="rounded-full font-bold bg-primary hover:bg-ternary text-white w-6 h-6 flex items-center justify-center cursor-pointer">-</div>
            <div class="px-2">{{ recipe.persons }}</div>
            <div @click="addPerson(1)" class="rounded-full font-bold bg-primary hover:bg-ternary text-white w-6 h-6 flex items-center justify-center cursor-pointer">+</div>
          </div>
        </div>
        <div class="flex justify-between w-full lg:w-1/2 px-2 mt-2 items-center">
          <label for="">Published</label>
          <input type="checkbox" v-model="recipe.published">
        </div>
        <div class="w-full mt-2 px-2">
          <label for="">Description</label>
          <textarea v-model="recipe.description" rows="3" class="w-full rounded border border-primary p-1"></textarea>
        </div>
        <div class="w-full px-2 mt-2">
          <label for="">Types</label>
          <div class="flex flex-wrap">
            <div v-for="type in recipeTypes" :key="type" class="p-1 flex items-center">
              <input type="checkbox" name="" id="" :checked="recipe.types.includes(type)" @input="toggleTypes(type)">
              <label for="" class="ml-1">{{ type }}</label>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-center mt-2 px-2">
          <div @click="save" class="rounded bg-primary text-ternary px-2 cursor-pointer">Opslaan</div>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/2 p-4">
      <div class="w-full h-full p-4 rounded border-2 border-primary flex flex-wrap">
        <h2 class="w-full font-medium text-lg text-center mb-4">Afbeeldingen</h2>

        <div class="w-full h-full flex flex-wrap">

          <div
            v-for="image in recipe.images" :key="image.id"
            class="w-24 h-24 rounded hover:border-4 border-primary cursor-pointer overflow-hidden m-1 relative"
            :class="{'border-4': recipe.preview && image.id === recipe.preview.id }"
            @click="setPreview(image)"
          >
            <ImageLoader
              :path="createImageUrl(recipe.id, image.id)"
              :alt="recipe.name"
              sizes="(min-width: 768px) 25vw, 50vw"
              :max="image.originalWidth"
            />
            <div
              class="absolute top-0 right-0 m-2 bg-primary px-1 rounded opacity-50 hover:opacity-100"
              @click.stop="deleteImage(image)"
            >
              <font-awesome-icon icon="trash-alt" />
            </div>
          </div>

          <label for="image-input" class="w-24 h-24 rounded bg-gray-200 hover:bg-gray-300 border border-gray-400 cursor-pointer flex items-center justify-center m-1">
            <font-awesome-icon icon="plus-circle" class="text-gray-500 text-xl" />
          </label>
          <input @change="addFiles" id="image-input" type="file" hidden multiple accept=".jpg,.png">
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/2 p-4">
      <div class="w-full h-full p-4 rounded border-2 border-primary flex flex-col">
        <h2 class="w-full font-medium text-lg text-center mb-4">Ingrediënten</h2>

        <div
          v-for="(ingredient, index) in recipe.recipeIngredients" :key="index"
          class="w-full flex items-center p-1 mt-2"
        >
          <div class="w-full rounded border border-primary p-2 flex flex-col xl:flex-row">
            <div class="w-full xl:w-1/2 px-1">
              <div class="w-full flex justify-between mt-2">
                <label for="">Item Singular</label>
                <input type="text" v-model="ingredient.nameSingular" class="rounded border border-primary px-2">
              </div>
              <div class="w-full flex justify-between mt-2">
                <label for="">Item Plural</label>
                <input type="text" v-model="ingredient.namePlural" class="rounded border border-primary px-2">
              </div>

              <div class="w-full flex justify-between mt-2">
                <label for="">Unit Singular</label>
                <input type="text" v-model="ingredient.unitSingular" class="rounded border border-primary px-2">
              </div>
              <div class="w-full flex justify-between mt-2">
                <label for="">Unit Plural</label>
                <input type="text" v-model="ingredient.unitPlural" class="rounded border border-primary px-2">
              </div>
            </div>
            <div class="w-full h-full xl:w-1/2 px-1">
              <div class="w-full flex justify-between mt-2">
                <label for="">Value</label>
                <input type="number" v-model="ingredient.value" class="rounded border border-primary px-2">
              </div>
              <div class="w-full flex justify-between mt-2">
                <label for="">Ingredient</label>
                <select
                  v-model="ingredient.ingredientId"
                  class="rounded border border-primary"
                >
                  <option value="" disabled>Kies een ingrediënt</option>
                  <option
                    v-for="ingr in ingredients" :key="ingr.id"
                    :value="ingr.id"
                  >{{ ingr.singular }}</option>
                </select>
              </div>

              <div class="w-full flex mt-auto pt-2">
                <div class="rounded bg-primary cursor-pointer text-ternary px-2 ml-auto mt-auto" @click="deleteIngredient(ingredient)">
                  <font-awesome-icon icon="trash-alt" />
                  Remove ingredient
                </div>
              </div>
              <div class="w-full flex mt-2">
                <div class="rounded bg-primary cursor-pointer text-ternary px-2 ml-auto" @click="saveIngredient(ingredient)">
                  Save ingredient
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-center mt-auto pt-2">
          <div @click="addIngredient" class="rounded bg-primary text-ternary px-2 cursor-pointer">Voeg ingrediënt toe</div>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/2 p-4">
      <div class="w-full h-full p-4 rounded border-2 border-primary flex flex-col">
        <h2 class="w-full font-medium text-lg text-center mb-4">Stappen</h2>
        <div
          v-for="(step, index) in recipe.steps" :key="index"
          class="w-full flex items-center px-4 mt-2"
        >
          <div class="whitespace-no-wrap">Stap {{ index + 1}}</div>
          <div class="w-full ml-2">
            <input type="text" v-model="step.value" class="rounded border border-primary w-full px-2">
          </div>
          <div class="ml-4 cursor-pointer" @click="deleteStep(index)">
            <font-awesome-icon icon="trash-alt" />
          </div>
        </div>
        <div class="w-full flex justify-center mt-auto pt-2">
          <div @click="addStep" class="rounded bg-primary text-ternary px-2 cursor-pointer">Voeg stap toe</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../../http'
import ImageLoader from '@/components/ImageLoader'

export default {
  components: {
    ImageLoader
  },
  data () {
    return {
      recipe: {
        id: null,
        createdAt: null,
        published: false,
        name: '',
        slug: '',
        cookTime: 0,
        prepTime: 0,
        description: '',
        persons: 4,
        steps: [],
        recipeIngredients: [],
        images: [],
        preview: null,
        types: []
      },
      recipeTypes: [],
      ingredients: []
    }
  },
  mounted () {
    this.getIngredients()
    this.getRecipeTypes()

    if (this.$route.params.recipe !== 'new') {
      this.load()
    }
  },
  methods: {
    createImageUrl (recipeId, imageId) {
      return `/api/recipes/${recipeId}/images/${imageId}`
    },
    async getRecipeTypes () {
      const res = await http.get('/api/recipe-types')

      this.recipeTypes = res.data
    },
    async getIngredients () {
      const res = await http.get('/api/ingredients')

      this.ingredients = res.data.sort((a, b) => {
        const textA = a.singular.toLowerCase()
        const textB = b.singular.toLowerCase()

        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
      })
    },
    toggleTypes (type) {
      const index = this.recipe.types.indexOf(type)

      if (index > -1) {
        this.recipe.types.splice(-1, 1)
      } else {
        this.recipe.types.push(type)
      }
    },
    async addFiles (event) {
      if (!this.recipe.id) return

      for (const file of event.target.files) {
        const form = new FormData()
        form.append('image', file)

        try {
          const res = await http.post(`/api/recipes/${this.recipe.id}/images`, form)

          this.recipe.images.push(res.data)

          this.$notify({
            type: 'success',
            title: 'Added image'
          })
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Adding image failed',
            text: e?.data?.message
          })
        }
      }
    },
    async deleteImage (image) {
      await http.delete(`/api/recipes/${this.recipe.id}/images/${image.id}`)

      const index = this.recipe.images.findIndex(im => im.id === image.id)
      this.recipe.images.splice(index, 1)
    },
    async setPreview (image) {
      await http.post(`/api/recipes/${this.recipe.id}/images/${image.id}/preview`)

      this.recipe.preview = image
    },
    addPerson (count) {
      this.recipe.persons += count
      if (this.recipe.persons < 1) this.recipe.persons = 1
    },
    addStep () {
      this.recipe.steps.push({ value: '' })
    },
    deleteStep (index) {
      this.recipe.steps.splice(index, 1)
    },
    addIngredient () {
      this.recipe.recipeIngredients.push({
        id: null,
        ingredientId: null,
        value: null,
        nameSingular: '',
        namePlural: '',
        unitSingular: '',
        unitPlural: ''
      })
    },
    async saveIngredient (ingredient) {
      if (!this.recipe.id) return

      try {
        const res = await http.post(`/api/recipes/${this.recipe.id}/ingredients`, ingredient)

        if (res?.data?.id) ingredient.id = res.data.id

        this.$notify({
          type: 'success',
          title: 'Saved ingredient'
        })
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Saving ingredient failed',
          text: e?.data?.message
        })
      }
    },
    async deleteIngredient (ingredient) {
      if (this.recipe.id && ingredient.id) {
        await http.delete(`/api/recipes/${this.recipe.id}/ingredients/${ingredient.id}`)
      }

      const index = this.recipe.recipeIngredients.indexOf(ingredient)

      this.recipe.recipeIngredients.splice(index, 1)
    },
    async save () {
      try {
        const res = await http.post('/api/recipes', {
          id: this.recipe.id,
          published: this.recipe.published,
          name: this.recipe.name,
          slug: this.recipe.slug,
          cookTime: this.recipe.cookTime,
          prepTime: this.recipe.prepTime,
          description: this.recipe.description,
          persons: this.recipe.persons,
          steps: this.recipe.steps.map(step => step.value),
          types: this.recipe.types
        })

        this.recipe.id = res.data.id

        this.$notify({
          type: 'success',
          title: 'Saved recipe'
        })
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Saving recipe failed',
          text: e?.data?.message
        })
      }
    },
    async load () {
      try {
        const res = await http.get(`/api/recipes/${this.$route.params.recipe}`)

        const recipe = res.data

        recipe.steps = recipe.steps.map(step => {
          return { value: step }
        })

        recipe.recipeIngredients.forEach(ingredient => {
          ingredient.ingredientId = ingredient.ingredient?.id
        })

        this.recipe = recipe
      } catch (e) {
        this.$router.push({ path: '/admin/recipes/new' })
      }
    }
  }
}
</script>
